<template>
    <div class="main" style="background:#fff;">
        <Header/>
        <div class="teacher-mod">
            <img style="width:100%" src="../assets/otherBanner.png">
            <HeaderTitle title="合作院校"/>
            <div class="blank20"></div>
            <div class="row" style="background:#3884D3;color:#fff;">
                <div class="item">院校类型</div>
                <div class="item">院校名称</div>
                <div class="item">合作类型</div>
                <div class="item" style="width:300px">所属地</div>
                <div class="item" style="width:300px">是否有校企合作需求</div>
            </div>
            <div class="pic-list">
                <div v-for="(item,index) in list" :key="index">
                    <div class="row" :style="{background:index%2===1?'#AFCEED':'#f4f4f4'}" style="font-size:14px">
                        <div class="item">{{item.school_type}}</div>
                        <div class="item">{{item.school_name}}</div>
                        <div class="item">{{item.cooperation_type}}</div>
                        <div class="item" style="width:300px">{{item.school_address}}</div>
                        <div class="item" style="width:300px">{{Number(item.is_need)?'是':'否'}}</div>
                    </div>
                </div>

            </div>
            <el-pagination
                v-if="Number(total)!==0"
                :current-page="page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="limit"
                :total="Number(total)"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>
<script>
export default {
    props:{
        arr:{
            type:Array,
            default:() => [],
        },
    },
    filters:{
        formatterCorType(val){
            return val;
        },
        formatterXiaoType(val){
            const obj = {
                0:'中职',
                1:'高职',
                2:'职业本科',
            }
            return obj[Number(val)];
        },
    },
    computed:{
    },
    data() {
        return {
            total:0,
            list:[],
            page:1,
            limit:20,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        handleSizeChange(size){
            this.limit = size;
            this.getData();
        },
        handleCurrentChange(page){
            this.page = page;
            this.getData();
        },
        async getData(){
            const params = {
                page:this.page,
                limit: this.limit,
            }
            const res = await this.$http.get('/api/index/getPartnerSchoolList',params);
            if(res){
                this.list = res.list;
                this.total = res.total;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.pic-list{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
    
}
.row{
    width:100%;
    display: flex;
    .item{
        width:200px;
        height: 37px;
        line-height: 37px;
        font-weight: 600;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5px;
        box-sizing: border-box;
    }
}
</style>
